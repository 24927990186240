.App {
    padding: 20px;
}

.token span {
    font-size: small;
    padding-right: 15px;
}

.tokenValue {
    max-width: 800px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tokenLabel {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.shop {
    display: flex;
    border: 1px solid lightgray;
    border-radius: 10px;
    margin-bottom: 15px;
}

.shop div {
    padding: 5px;
    justify-content: center;
    height: 80px;
    line-height: 80px;
}
.shopImage {
    height: 80px;
    border-radius: 10px;
}
.shopName {
    margin-left: 10px;
    width: 50%;
}

.error{
    color: red;
    font-size: larger;
}
